import { useTranslation } from 'next-i18next'
import { createGraphQLClient } from 'graphql/contentfulClient'

import { Image } from '~/elements/Image/Image'
import { useNavigationQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type ItemProps = {
  data: { icon?: string | null; boldText?: string | null; normalText?: string | null }
}

export const IconBar = () => {
  const { t } = useTranslation()
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const items = data?.pageCollection?.items?.[0]?.footerIconBarCollection?.items || []

  const Item = ({ data }: ItemProps) => {
    return (
      <div className="flex flex-col items-center p-4">
        <Image
          src={`/images/IconBarIcons/${data.icon}.svg`}
          placeholder="empty"
          width={56}
          height={56}
          alt={data.icon ?? 'usp-icon'}
        />
        {data.boldText && <p className="text-center font-bold">{data.boldText}</p>}
        {data.normalText && <p className="text-center">{data.normalText}</p>}
      </div>
    )
  }

  return (
    <section aria-label={t('Our four selling points')} className="border-t border-grey-border">
      <div className="flex flex-row flex-wrap justify-evenly p-4 md:p-8 lg:p-20">
        {items.map((item) => item?.icon && <Item key={item.sys.id} data={item} />)}
      </div>
    </section>
  )
}
